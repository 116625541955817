import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./assets/global/styles.css";
import OxfordTest from "./layouts/OxfordTest";

export default function App() {
  return (
    <>
      <div>
        <Switch>
          <Route
            exact
            path="/:languageParam"
            render={(props) => <OxfordTest {...props} />}
          />
          <Route exact path="/" render={(props) => <OxfordTest {...props} />} />
          <Redirect to="/" />
        </Switch>
      </div>
    </>
  );
}
