import React, { useState } from "react";
import oxfordLogo from "../assets/oxford/oxford-logo.png";
import heroImg from "../assets/oxford/hero-img.png";
import appWhiteStore from "../assets/oxford/apple-white-logo.png";
import googlePlay from "../assets/oxford/google-play.png";
import appStoreBlack from "../assets/oxford/app-store-black.png";
import googlePlayBlack from "../assets/oxford/google-store-black.png";
import trustLogo from "../assets/oxford/trust-logo.svg";
import confidenceLogo from "../assets/oxford/confidence-logo.svg";
import livelinessLogo from "../assets/oxford/liveliness-logo.svg";
import textbookImg from "../assets/oxford/textbook-img.png";
import methodImg from "../assets/oxford/method-img.png";
import friendImg from "../assets/oxford/friend-img.png";
import { OxfordComponents } from "../components/oxford";
import useGeoLocation from "react-ipgeolocation";
import { languageData, selectLanguages } from "../data/language-2";
import { useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router";
import 'animate.css';
import WOW from 'wow.js'

const OxfordTest = () => {
  let supportedLanguages = ["EN", "KR", "CN", "ID", "JP", "BR", "IN", "VN"]
  const { languageParam } = useParams();
  let location = useGeoLocation();
  let lang;
  // let initialLanguageData = undefined
  if (languageParam) {
    lang = (supportedLanguages.includes(languageParam.toUpperCase())) ? languageParam.toUpperCase() : "EN"
  }
  useEffect(() => {
    if (!languageParam && location.country) {
      if (supportedLanguages.includes(location.country)) {
        setInitialLanguage(location.country)
        setLanguage(location.country)
      } else {
        console.log(location.country)
        setInitialLanguage('EN')
        setLanguage('EN')
      }
    }
  }, [location.country])
  const [language, setLanguage] = useState(lang)
  const [initialLanguage, setInitialLanguage] = useState(lang);
  // const [data, setData] = useState({});
  const [isLanguageSwitchOpen, setIsLanguageSwitchOpen] = useState(false);
  function returnTitle(language) {
    if (language === "KR") {
      return (
        <>
          <span>뮤지오 톡</span>
          영어도 놀이처럼!
          <br />
          스마트하게
        </>
      )
    } else if (language === "CN") {
      return (
        <>
          <span>小缪智学</span>
          与你的好奇新朋友
          <br />
          小缪一起
        </>
      )
    } else if (language === "ID") {
      return (
        <>
          <span>MUSIO TALK</span>
          Belajar dengan teman
        </>
      )
    } else if (language === "BR") {
      return (
        <>
          <span>MUSIO TALK</span>
          Aprenda inglês
          <br />
          com seu
          <br />
          amigo curioso
        </>
      )
    } else if (language === "JP") {
      return (
        <>
          <span>MUSIO TALK</span>
          英語も遊びのように！
        </>
      )
    } else if (language === "VN") {
      return (
        <>
          <span>MUSIO TALK</span>
          Học Anh ngữ cùng bạn bè
        </>
      )
    } else if (language === "IN") {
      return (
        <>
          <span>MUSIO TALK</span>
          अपने जिज्ञासु दोस्त के साथ
          <br />
          अंग्रेजी सीखें
        </>
      )
    }
    else {
      return (
        <>
          <span>MUSIO TALK</span>
          Learn English with your curious friend
        </>

      )
    }
  }

  function returnSubtitle(language) {
    if (language === "KR") {
      return (
        <p>
          최고의{" "}
          <span>
            <strong>콘텐츠</strong>
          </span>
          와 영어회화{" "}
          <span>
            <strong>친구</strong>
          </span>
          를 한 번에!
        </p>
      );
    } else if (language === "CN") {
      return (
        <p>
          从<span>人气教材</span>到<span>英语口语</span>全方位！
        </p>
      );
    } else if (language === "ID") {
      return (
        <p>
          <span>Belajar</span> dan <span>bicara</span> bahasa Inggris jadi
        </p>
      );
    } else if (language === "JP") {
      return (
        <p className="jp">
          最高の<span>コンテンツ</span>と英会話の<span>友達</span>を一度に！
        </p>
      )
    } else if (language === "BR") {
      return (
        <p>
          Melhor chat de <span>estudo</span> e <span>bate-papo</span>!
        </p>
      )
    } else if (language === "IN") {
      return (
        <p>
          बेस्ट <span>स्टडी</span> और अंग्रेजी में <span>चैटिंग</span> सब कुछ एक ही जगह!
        </p>
      )
    } else if (language === "VN") {
      return (
        <p>
          Nền tảng tích hợp <span>học tập</span> và <span>giao tiếp</span> tiếng Anh tốt nhất!
        </p>
      )
    } else {
      return (
        <p className="subtitile">
          Best <span>study</span> and English <span>chatting</span>{" "}
          all-in-one!
        </p>
      );
    }
  }

  function returnSiteTitle1(language) {
    if (language === "KR") {
      return (
        <>
          아이들이 좋아하는 <span>인기 교재</span>
        </>
      )
    } else if (language === "CN") {
      return (
        <>
          最适合我们孩子的教材
        </>
      )
    } else if (language === "ID") {
      return (
        <>
          Buku Bahasa Inggris Pemenang Penghargaan
        </>
      )
    } else if (language === "BR") {
      return (
        <>
          Livro de Inglês Premiado
        </>
      )
    } else if (language === "JP") {
      return (
        <>
          子供達が好きな<span>人気の教材</span>
        </>
      )
    } else if (language === "VN") {
      return (
        <>
          Một trong những bộ sách tiếng Anh bán chạy nhất trên thế giới
        </>
      )
    } else if (language === "IN") {
      return (
        <>
          अवार्ड विजेता <span>अंग्रेजी पाठ्य पुस्तक</span>
        </>
      )
    } else {
      return (
        <>
          Award-winning <span>English Textbook</span>
        </>
      )
    }
  }

  function returnSiteTitle2(language) {
    if (language === "KR") {
      return (
        <>
          <span>AI 튜터 뮤지오와 하는</span>
          <br />
          <span>유일한 영어학습 공간</span>
        </>
      )
    } else if (language === "CN") {
      return (
        <>
          智能学习法，自己<span>在家也能完成学习</span>
        </>
      )
    } else if (language === "ID") {
      return (
        <>
          Metode Pembelajaran Interaktif
        </>
      )
    } else if (language === "BR") {
      return (
        <>
          Método de Aprendizado Interativo
        </>
      )
    } else if (language === "JP") {
      return (
        <>
          <span>AIチューターMusioと行う</span>
          <br />
          <span>英語学習空間</span>
        </>
      )
    } else if (language === "VN") {
      return (
        <>
          Phương pháp học tương tác
        </>
      )
    } else if (language === "ID") {
      return (
        <>
          इंटरैक्टिव शिक्षा <span>पद्धति</span>
        </>
      )
    } else {
      return (
        <>
          Interactive Learning <span>Method</span>
        </>
      )
    }
  }

  function returnSiteTitle3(language) {
    if (language === "KR") {
      return (
        <>
          <span>진짜보다 더 진짜 같은</span>
          <br />
          <span>AI 채팅 회화</span>
        </>
      )
    } else if (language === "CN") {
      return (
        <>
          真正的AI对话伙伴
        </>
      )
    } else if (language === "ID") {
      return (
        <>
          Teman Ngobrol AI
        </>
      )
    } else if (language === "BR") {
      return (
        <>
          Amigo de Chat de IA Real
        </>
      )
    } else if (language === "JP") {
      return (
        <>
          <span>リアル感満載の</span>
          <br />
          <span>AIチャット</span>
        </>
      )
    } else if (language === "VN") {
      return (
        <>
          Người bạn AI giống thực hơn bạn nghĩ
        </>
      )
    } else if (language === "IN") {
      return (
        <>
          असली AI चैटिंग <span>दोस्त</span>
        </>
      )
    } else {
      return (
        <>
          Real AI Chatting <span>Friend</span>
        </>
      )
    }
  }

  useEffect(() => {
    new WOW().init()
  }, [])
  // useEffect(() => {
  //   if (location.country) {
  //     if (languageParam.toUpperCase() === "EN") {
  //       setInitialLanguage("EN");
  //       setLanguage("EN");
  //       setData(languageData[languageParam.toUpperCase()]);
  //     } else if (languageParam.toUpperCase() === "KR") {
  //       setInitialLanguage("KR");
  //       setLanguage("KR");
  //       setData(languageData[languageParam.toUpperCase()]);
  //     } else if (languageParam.toUpperCase() === "ID") {
  //       setInitialLanguage("ID");
  //       setLanguage("ID");
  //       setData(languageData[languageParam.toUpperCase()]);
  //     } else if (languageParam.toUpperCase() === "CN") {
  //       setInitialLanguage("CN");
  //       setLanguage("CN");
  //       setData(languageData[languageParam.toUpperCase()]);
  //     } else if (languageParam.toUpperCase() === "JP") {
  //       setInitialLanguage("JP");
  //       setLanguage("JP");
  //       setData(languageData[languageParam.toUpperCase()]);
  //     } else if (languageParam.toUpperCase() === "BR") {
  //       setInitialLanguage("BR");
  //       setLanguage("BR");
  //       setData(languageData[languageParam.toUpperCase()]);
  //     } else if (languageParam.toUpperCase() === "IN") {
  //       setInitialLanguage("IN");
  //       setLanguage("IN");
  //       setData(languageData[languageParam.toUpperCase()]);
  //     } else if (languageParam.toUpperCase() === "VN") {
  //       setInitialLanguage("VN");
  //       setLanguage("VN");
  //       setData(languageData[languageParam.toUpperCase()]);
  //     } else {
  //       setInitialLanguage("KR");
  //       setLanguage("KR");
  //       setData(languageData["KR"]);
  //     }
  //   }
  // }, [languageParam, location.country]);


  function handleSwitch() {
    setIsLanguageSwitchOpen((s) => !s);
  }

  function handleSwitchLanguage(e, newLang) {
    e.stopPropagation();
    setLanguage(newLang);
    handleSwitch();
  }

  function returnLanguage(code) {
    if (code === "KR") {
      return "한국어";
    } else if (code === "CN") {
      return "中文";
    } else if (code === "ID") {
      return "Bahasa Indonesian";
    } else if (code === "JP") {
      return "日本語"
    } else if (code === "BR") {
      return "Brazilian Portuguese"
    } else if (code === "IN") {
      return "Hindi"
    } else if (code === "VN") {
      return "Vietnamese"
    } else {
      return "English";
    }
  }


  if (language) return (
    <>
      <OxfordComponents.Navbar>
        <img src={oxfordLogo} alt="oxford logo" onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }} />
        <OxfordComponents.NavbarLeft>
          {isLanguageSwitchOpen ? (
            <OxfordComponents.CustomButton onClick={handleSwitch}>
              {selectLanguages[initialLanguage].map((option) => (
                <span
                  onClick={(e) => handleSwitchLanguage(e, option.code)}
                  key={option.label}
                >
                  {option.label}
                </span>
              ))}
            </OxfordComponents.CustomButton>
          ) : (
            <OxfordComponents.CustomButton
              onClick={handleSwitch}
              arrow="down"
            >
              {returnLanguage(language)}
            </OxfordComponents.CustomButton>
          )}
        </OxfordComponents.NavbarLeft>
      </OxfordComponents.Navbar>
      <OxfordComponents.Container>
        {/* <OxfordComponents.Navbar>
          <img src={oxfordLogo} alt="oxford logo" onClick={() => {window.scrollTo({top: 0, behaviour: 'smooth'})}}/>
          <OxfordComponents.NavbarLeft>
            {isLanguageSwitchOpen ? (
              <OxfordComponents.CustomButton onClick={handleSwitch}>
                {selectLanguages[initialLanguage].map((option) => (
                  <span
                    onClick={(e) => handleSwitchLanguage(e, option.code)}
                    key={option.label}
                  >
                    {option.label}
                  </span>
                ))}
              </OxfordComponents.CustomButton>
            ) : (
              <OxfordComponents.CustomButton
                onClick={handleSwitch}
                arrow="down"
              >
                {returnLanguage(language)}
              </OxfordComponents.CustomButton>
            )}
          </OxfordComponents.NavbarLeft>
        </OxfordComponents.Navbar> */}
        <OxfordComponents.Header>
          <div className="left-side wow animate__animated animate__fadeInUp">
            <div>
              <h2>
                {returnTitle(language)}
              </h2>
            </div>
            {returnSubtitle(language)}
            <div className="app-container">
              <a href="https://apps.apple.com/us/app/musio-talk-lets-go-edition/id1572775674">
                <img src={appStoreBlack} alt="app-store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=kr.akaai.oxford_android">
                <img src={googlePlayBlack} alt="play-store" />
              </a>
            </div>
          </div>
          <div className="right-side">
            <img src={heroImg} alt="" />
          </div>
        </OxfordComponents.Header>
      </OxfordComponents.Container>
      <OxfordComponents.BlueBlockContainer>
        <OxfordComponents.BlueBlock>
          <div className="content-container">
            <div className="block-text">
              <img src={trustLogo} alt="" />
              <div className="blue-block-text wow animate__animated animate__fadeInUp">
                <h4>{languageData[language].point1.title}</h4>
                <p>{languageData[language].point1.subtitle}</p>
              </div>
            </div>
            <div className="block-text">
              <img src={confidenceLogo} alt="" />
              <div className="blue-block-text wow animate__animated animate__fadeInUp">
                <h4>{languageData[language].point2.title}</h4>
                <p>{languageData[language].point2.subtitle}</p>
              </div>
            </div>
            <div className="block-text">
              <img src={livelinessLogo} alt="" />
              <div className="blue-block-text wow animate__animated animate__fadeInUp">
                <h4>{languageData[language].point3.title}</h4>
                <p>{languageData[language].point3.subtitle}</p>
              </div>
            </div>
          </div>
        </OxfordComponents.BlueBlock>
      </OxfordComponents.BlueBlockContainer>
      <OxfordComponents.Features>
        <div className="feature-content">
          <img src={textbookImg} alt="" />
          <div className="text-box wow animate__animated animate__fadeInUp">
            {/* <h5
              className={
                language === "KR"
                  ? "KR first-one"
                  : language === "EN"
                  ? "EN"
                  : ""
              }
            >
              {data.site1.title1}
            </h5> */}
            <h5>{returnSiteTitle1(language)}</h5>
            <p>{languageData[language].site1.subtitle}</p>
          </div>
        </div>
        <div className="feature-content-background ">
          <div className="feature-content reverse">
            <img src={methodImg} alt="" />
            <div className="text-box wow animate__animated animate__fadeInUp">
              {/* {language === "KR" ? (
                <>
                  <h5 className="kr">
                    <span>AI 튜터 뮤지오와 하는 </span>
                    <span>유일한 영어학습 공간</span>
                  </h5>
                </>
              ) : (
                <h5>{data.site2.title1}</h5>
              )} */}
              <h5>
                {returnSiteTitle2(language)}
              </h5>
              <p>{languageData[language].site2.subtitle}</p>
            </div>
          </div>
        </div>
        <div className="feature-content">
          <img src={friendImg} alt="" />
          <div className="text-box wow animate__animated animate__fadeInUp">
            {/* <h5
              className={
                language === "KR"
                  ? "KR last-one"
                  : language === "EN"
                  ? "EN"
                  : ""
              }
            >
              {data.site3.title1}
            </h5> */}
            <h5>
              {returnSiteTitle3(language)}
            </h5>
            <p>{languageData[language].site3.subtitle}</p>
          </div>
        </div>
        <OxfordComponents.AppsIntroduction>
          <div className="wow animate__animated animate__fadeInUp">
            <h3>{languageData[language].cta.title}</h3>
            <div>
              <a href="https://apps.apple.com/us/app/musio-talk-lets-go-edition/id1572775674">
                <img className="mr" src={appWhiteStore} alt="app-store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=kr.akaai.oxford_android">
                <img src={googlePlay} alt="play-store" />
              </a>
            </div>
          </div>
        </OxfordComponents.AppsIntroduction>
      </OxfordComponents.Features>
      <OxfordComponents.Footer>
        <p>{languageData[language].legal.title}</p>
      </OxfordComponents.Footer>
    </>
  )
  return <></>
};

export default OxfordTest;
