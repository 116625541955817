export const languageData = {
  KR: {
    title: {
      line1: "뮤지오 톡",
      line2: "영어도 놀이처럼!",
      line3: "스마트하게",
      subtitle: "최고의 콘텐츠와 영어회화 친구를 한 번에!",
    },

    point1: {
      title: "믿음 뿜뿜",
      subtitle:
        "영국 옥스포드 출판사 인기 교재Let's Go 시리즈로 공부해요!",
    },
    point2: {
      title: "자신감 뿜뿜",
      subtitle:
        "AI 튜터 뮤지오와 함께하는 스스로 학습으로 자신감을 키워요",
    },
    point3: {
      title: "생동감 뿜뿜",
      subtitle: "AI 친구 뮤지오와의 실생활 영어 회화로 진짜 영어를 배워요",
    },
    site1: {
      title1: "아이들이 좋아하는 인기 교재",
      subtitle:
        "이유 있는 베스트셀러 Let’s go 교재를 옥스포드 고유의 학습법과 Musio Talk의 특별한 EDU 시스템을 통해 더욱 재미있게 체계적으로 배워요!",
    },
    site2: {
      title1: "선생님 없이 하는 유일한 영어학습 공간",
      subtitle:
        "뮤지오 톡만의 교육시스템과 실시간 피드백 기능으로 Role-play 부터 Test 까지! 탄탄한 학습과정을 통해 스피킹 실력을 향상시켜요!",
    },
    site3: {
      title1: "진짜보다 더 진짜 같은 AI 채팅 회화",
      subtitle:
        "상황별로 다양한 표현을 추천해 주는 Learner’s Chat. 주제 제한 없이 자유롭게 대화가 가능 한 Free Talk. 두 가지 프리미엄 기능을 무제한으로 사용하며 스피킹 실력을 풍부하게 키워요!",
    },
    cta: {
      title: "지금 바로 시작하세요.",
    },
    legal: {
      title: "©2022 AKA AI. All rights reserved.",
    },
  },
  EN: {
    title: {
      line1: "MUSIO TALK",
      line2: "Learn English with your curious friend",
      line3: "",
      subtitle: "Best study and English chatting all-in-one!",
    },
    point1: {
      title: "Trust",
      subtitle:
        "Let’s Go series widely used in over 160 countries in the world!",
    },
    point2: {
      title: "Confidence",
      subtitle:
        "Learn English with AI tutor Musio and upgrade your confidence!",
    },
    point3: {
      title: "Liveliness",
      subtitle: "Do real English chatting with AI friend Musio!",
    },
    site1: {
      title1: "Award-winning English Textbook",
      subtitle:
        "With Oxford University Press's Let's Go 5th Edition, students can learn English with trusted methodology together with fun, collaborative activities. Musio Talk can inspire students to enjoy learning English and get the talking from the very beginning.",
    },
    site2: {
      title1: "Interactive Learning Method",
      subtitle:
        "EDU Mode enables students to learn with a series of activities that focus on developing listening and speaking skills through listening, repeating, pronunciation feedback, and even role-play. This system allows natural learning adoption without time-consuming rote learning.",
    },
    site3: {
      title1: "Real AI Chatting Friend",
      subtitle:
        "Whether it's suggestion-based mode Learn's Chat or boundaryless conversation mode Free Talk, with AI friend Musio, students can chat in abundant topics and role-play settings that they can ever imagine.",
    },
    cta: {
      title: "Start your English journey now.",
    },
    legal: {
      title: "©2022 AKA AI. All rights reserved.",
    },
  },
  CN: {
    title: {
      line1: "小缪智学",
      line2: "与你的好奇新朋友",
      line3: "小缪一起",
      subtitle: "从人气教材到英语口语全方位!",
    },

    point1: {
      title: "可信度满满",
      subtitle: "牛津少儿英语系列教材在全世界超过160个国家受到广泛赞誉!",
    },
    point2: {
      title: "自信感满满",
      subtitle: "与AI助教小缪一起开口讲英语，提高自信心!",
    },
    point3: {
      title: "生动感满满",
      subtitle: "与AI伙伴小缪一起，进行真正的AI英语对话!",
    },

    site1: {
      title1: "最适合我们孩子的教材",
      subtitle:
        "《牛津少儿英语》是一套专为英语初学者所设计的教材，从研究和务实中融合中西方教育的特点研发出的一系列适合中国学生的英语教材。该教材根据小学生的生理心理特点，以故事为主要内容，以剧本为主要载体，以及以游戏为主要学习手段是Let's Go!教材的主要特点。",
    },
    site2: {
      title1: "智能学习法，自己在家也能完成学习",
      subtitle:
        "小缪智学通过EDU模式和四步学习法，与牛津大学出版社合作将《牛津少儿英语》系列教材通过手机软件也能在家轻松完成学习并提供实时的学习反馈，以及运用各种小活动自然而然地学会教材的内容，让孩子在学习的过程中愉快的开口讲英语。",
    },
    site3: {
      title1: "真正的AI对话伙伴",
      subtitle:
        "AI Talk对话功能搭载了强大的Muse对话引擎。与其他引擎不同，Muse侧重于把日常对话内容融入到人机互动中，让学生们在与Musio交朋友的过程中练习到真正的英语口语。针对初学者研发的对话学习模式还能在交流的过程中给用户提出对答建议，从而学会更自然的交流方式。",
    },
    cta: {
      title: "开始你的学习之旅吧!",
    },
    legal: {
      title: "©2022 AKA AI. All rights reserved.",
    },
  },
  ID: {
    title: {
      line1: "MUSIO TALK",
      line2: "Belajar dengan teman",
      subtitle: "Belajar dan bicara bahasa Inggris jadi satu!",
    },

    point1: {
      title: "Kepercayaan",
      subtitle: "Let's Go digunakan di lebih dari 160 negara!",
    },
    point2: {
      title: "Percaya Diri",
      subtitle:
        "Belajar bersama tutor AI Musio dan tingkatkan kepercayaan dirimu!",
    },
    point3: {
      title: "Keaktifan",
      subtitle:
        "Lakukan percakapan bahasa Inggris langsung dengan teman AI Musio!",
    },

    site1: {
      title1: "Buku Bahasa Inggris Pemenang Penghargaan",
      subtitle:
        "Dengan Oxford University Press Let's Go 5th Edition, siswa dapat belajar bahasa Inggris dengan aktivitas kolaborasi menyenangkan dari metode terpercaya. Musio Talk dapat membuat siswa menikmati dan memahami pembelajaran sejak awal.",
    },
    site2: {
      title1: "Metode Pembelajaran Interaktif",
      subtitle:
        "Mode EDU memungkinkan siswa belajar dari aktivitas yang mengembangkan kemampuan mendengarkan dan berbicara dengan cara mendengarkan, mengulangi, masukan pelafalan, dan peran. Sistem ini lebih efektif dan hemat waktu.",
    },
    site3: {
      title1: "Teman Ngobrol AI",
      subtitle:
        "Entah itu Learn's Chat mode saran atau Free Talk bebas, dengan teman AI Musio, siswa dapat mengobrol tentang banyak topik dan bermain peran yang dapat mereka bayangkan.",
    },
    cta: {
      title: "Mulai perjalananmu sekarang!",
    },
    legal: {
      title: "©2022 AKA AI. All rights reserved.",
    },
  },
  JP: {
    title: {
      line1: "MUSIO TALK",
      line2: "英語も遊びのように！",
      line3: "",
      subtitle: "最高のコンテンツと英会話の友達を一度に！",
    },
    point1: {
      title: "信頼できる",
      subtitle:
        "Oxfordの人気教材「Let's Go」シリーズで勉強しましょう！",
    },
    point2: {
      title: "自信満々",
      subtitle:
        "AIチューター「Musio」と一緒にする自主学習で、自信を育てましょう！",
    },
    point3: {
      title: "躍動感たっぷり",
      subtitle: "AIの友達「Musio」との日常会話で本当の英語を学びます。",
    },
    site1: {
      title1: "子供達が好きな人気の教材",
      subtitle:
        "ベストセラーの「Let's Go」を活用！英国オックスフォード固有の学習法とMusio Talkが提供する特別なカリキュラムを通じて、より楽しく学習します。",
    },
    site2: {
      title1: "AIチューターMusioと行う 英語学習空間",
      subtitle:
        "MusioTalkならではの教育システムとリアルタイムフィードバック機能でRole-playからTestまで！ しっかりした学習過程を通じてスピーキングの実力を向上させます！",
    },
    site3: {
      title1: "リアル感満載の AIチャット",
      subtitle:
        "会話の状況別に様々な表現を提案するLearner's Chat！会話テーマの制限無しに、自由に会話ができるFree Talk!！2つのPremium機能を無制限に使用して、会話の実力を育ててみてください！",
    },
    cta: {
      title: "早速始めましょう！",
    },
    legal: {
      title: "©2022 AKA AI. All rights reserved.",
    },
  },
  BR: {
    title: {
      line1: "MUSIO TALK",
      line2: "Aprenda inglês com seu",
      line3: "amigo curioso",
      subtitle: "Melhor chat de estudo e bate-papo!",
    },
    point1: {
      title: "Confiança",
      subtitle:
        "Let's Go é amplamente utilizado em mais de 160 países!",
    },
    point2: {
      title: "Convicção",
      subtitle:
        "Aprenda inglês com o tutor de IA Musio e melhore sua confiança!",
    },
    point3: {
      title: "Animação",
      subtitle: "Faça um bate-papo em inglês real com o amigo da IA, Musio!",
    },
    site1: {
      title1: "Livro de Inglês Premiado",
      subtitle:
        "Com Let's Go 5ª Edição da Oxford University Press, os alunos podem aprender inglês com metodologia confiável, juntamente com atividades divertidas e colaborativas. O Musio Talk pode inspirar os alunos a gostar de aprender inglês e falar desde o início.",
    },
    site2: {
      title1: "Método de Aprendizado Interativo",
      subtitle:
        "O Modo EDU permite que os alunos aprendam com uma série de atividades que se concentram no desenvolvimento de habilidades de escuta e fala por meio de escuta, repetição, feedback de pronúncia e até dramatização. Este sistema permite o aprendizado natural sem ser mecânico.",
    },
    site3: {
      title1: "Amigo de Chat de IA Real",
      subtitle:
        "Seja no modo de sugestão baseado em bate-papo do Learn ou no modo de conversação sem limites, com o amigo de IA Musio, os alunos podem conversar em tópicos diversos e configurações de encenação que eles podem imaginar.",
    },
    cta: {
      title: "Inicie sua jornada no inglês agora.",
    },
    legal: {
      title: "©2022 AKA AI. All rights reserved.",
    },
  },
  IN: {
    title: {
      line1: "MUSIO TALK",
      line2: "अपने जिज्ञासु दोस्त के साथ",
      line3: "अंग्रेजी सीखें",
      subtitle: "बेस्ट स्टडी और अंग्रेजी में चैटिंग सब कुछ एक ही जगह!",
    },
    point1: {
      title: "भरोसा",
      subtitle:
        "Let’s Go सीरीज़ का पूरी दुनिया में 160 से अधिक देशों में उपयोग होता है!",
    },
    point2: {
      title: "आत्मविश्वास",
      subtitle:
        "AI ट्यूटर Musio के साथ अंग्रेजी सीखें और अपना आत्मविश्वास बढ़ाएँ!",
    },
    point3: {
      title: "जीवंतता",
      subtitle: "AI दोस्त Musio के साथ स्वाभाविक अंग्रेजी में चैटिंग करें!",
    },
    site1: {
      title1: "अवार्ड विजेता अंग्रेजी पाठ्य पुस्तक",
      subtitle:
        "ऑक्सफोर्ड यूनिवर्सिटी प्रेस के Let's Go पाँचवाँ संस्करण के साथ, स्टूडेंट्स भरोसेमंद पद्धति के साथ-साथ मजेदार, सहयोगात्मक गतिविधियों के ज़रिए अंग्रेजी सीख सकते हैं। Musio Talk स्टूडेंट्स को अंग्रेजी सीखने के लिए प्रेरित कर सकता है और शुरूआत से ही बोलचाल आरंभ कर देता है।",
    },
    site2: {
      title1: "इंटरैक्टिव शिक्षा पद्धति",
      subtitle:
        "EDU मोड स्टूडेंट्स को ऐसी क्रमिक गतिविधियों की सहायता से सीखने में सक्षम बनाता है जो सुनने और बोलने के कौशल को सुनकर, दोहराकर, उच्चारण फ़ीडबैक, और यहाँ तक कि रोल-प्ले के भी ज़रिए विकसित करने पर ध्यान केंद्रित करती हैं। यह सिस्टम सहज शिक्षा को अपनाने में सक्षम बनाता है जिसमें बहुत समय लेने वाली रट्टामार शिक्षा शामिल नहीं होती है।",
    },
    site3: {
      title1: "असली AI चैटिंग दोस्त",
      subtitle:
        "चाहे इसका सुझाव-आधारित मोड Learn's Chat हो या सीमारहित बातचीत मोड Free Talk हो, AI दोस्त Musio के साथ, स्टूडेंट्स इतने ढेर सारे विषयों पर और रोल प्ले सेटिंग में बातचीत कर सकते हैं जिनकी वे कल्पना भी नहीं कर सकते।",
    },
    cta: {
      title: "अपनी अंग्रेजी की यात्रा अभी शुरू करें।",
    },
    legal: {
      title: "©2022 AKA AI. सर्वाधिकार सुरक्षित।",
    },
  },
  VN: {
    title: {
      line1: "MUSIO TALK",
      line2: "Học Anh ngữ cùng bạn bè",
      line3: "",
      subtitle: "Nền tảng tích hợp học tập và giao tiếp tiếng Anh tốt nhất!",
    },
    point1: {
      title: "Uy tín",
      subtitle:
        "Bộ sách Let's Go được sử dụng rộng rãi tại hơn 160 quốc gia trên khắp thế giới!",
    },
    point2: {
      title: "Tự tin",
      subtitle:
        "Học tiếng Anh cùng gia sư AI Musio và nâng cao sự tự tin của bạn!",
    },
    point3: {
      title: "Sống động",
      subtitle: "Trò chuyện tiếng Anh thực tiễn cùng người bạn AI Musio!",
    },
    site1: {
      title1: "Một trong những bộ sách tiếng Anh bán chạy nhất trên thế giới",
      subtitle:
        "Nhờ phương pháp học thông minh đến từ phiên bản Let's Go thứ 5 - một trong những bộ sách bán chạy nhất thế giới của Nhà xuất bản Đại học Oxford, các bài giảng của chúng tôi sẽ luôn tạo được niềm hứng khởi học tập trong mỗi người dùng, thúc đẩy quá trình phát triển của bản thân.",
    },
    site2: {
      title1: "Phương pháp học tương tác",
      subtitle:
        "Kết hợp hệ thống học nguyên bản của AKA, EDU Mode cho phép người dùng học bộ sách Let's Go phiên bản thứ 5 dựa trên hàng loạt các hoạt động tập trung vào quá trình phát triển kĩ năng nghe và nói. Người dùng sẽ có thể thực hành các ngữ liệu trong sách giáo khoa thông qua hoạt động nghe, nhắc lại, phản hồi về phát âm và thậm chí là nhập vai. Hệ thống này sẽ giúp bạn ghi nhớ bài học một cách tự nhiên mà không cần phải học thuộc lòng một cách nhàm chán!",
    },
    site3: {
      title1: "Người bạn AI giống thực hơn bạn nghĩ",
      subtitle:
        "AI Talk là một tính năng cao cấp giúp người dùng thoải mái giao tiếp tiếng Anh thông qua người bạn AI Musio . Các chế độ Learner's Chat - Trò chuyện dựa trên gợi ý và chế độ Free Talk - Trò chuyện tự do sẽ đưa bạn vào vô số các tình huống nhập vai và chủ đề mà bạn còn chưa hề nghĩ đến",
    },
    cta: {
      title: "Chinh phục tiếng Anh ngay từ bây giờ!",
    },
    legal: {
      title: "©2022 AKA AI. Bảo lưu mọi quyền.",
    },
  }
};

export const selectLanguages = {
  ID: [
    {
      label: "English",
      code: "EN",
    },
    {
      label: "Bahasa Indonesia",
      code: "ID",
    },
  ],
  CN: [
    {
      label: "English",
      code: "EN",
    },
    {
      label: "中文",
      code: "CN",
    },
  ],
  KR: [
    {
      label: "English",
      code: "EN",
    },
    {
      label: "한국어",
      code: "KR",
    },
  ],
  EN: [
    {
      label: "Korean",
      code: "KR",
    },
    {
      label: "English",
      code: "EN",
    },
  ],
  JP: [
    {
      label: "English",
      code: "EN",
    },
    {
      label: "日本語",
      code: "JP",
    },
  ],
  BR: [
    {
      label: "English",
      code: "EN",
    },
    {
      label: "Brazilian Portuguese",
      code: "BR",
    },
  ],
  IN: [
    {
      label: "English",
      code: "EN",
    },
    {
      label: "Hindi",
      code: "IN",
    },
  ],
  VN: [
    {
      label: "English",
      code: "EN",
    },
    {
      label: "Vietnamese",
      code: "VN",
    },
  ]
};
