import styled from "styled-components";
import appsIntroDesktop from "../../assets/oxford/app-intro-desktop.svg";
import downArrow from "../../assets/oxford/down-arrow.svg";
import upArrow from "../../assets/oxford/up.svg";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 70px;
  width: 90%;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
`;

const Navbar = styled.nav`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img:hover {
    cursor: pointer;
  }
  position: fixed;
  width: 100%;
  top: 0;
  height: 70px;
  padding-left: 5%;
  padding-right: 5%;
  background-color: white;
  /* box-shadow: 0 2px 4px 0 rgba(0,0,0,.2); */
`;
const CustomButton = styled.button`
  position: absolute;
  right: 0;
  top: 10px;
  font-family: "Noto Sans";
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #343a40;
  background: #f2f2f2;
  border-radius: 20px;
  padding: 8px 29px 8px 17px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  &::after {
    content: "";
    ${(props) =>
      props.arrow === "down"
        ? `background: url(${downArrow});`
        : `background: url(${upArrow});`}

    position: absolute;
    top: 16px;
    right: 10px;
    z-index: 50;
    width: 10px;
    height: 6px;
  }
  span:first-child {
    margin-bottom: 12px;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Noto Sans";
  margin-top: 85px;

  @media (max-width: 680px) {
    margin-top: 40px;
  }
  @media (max-width: 760px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .left-side {
    @media (max-width: 680px) {
      margin: 0;
      /* width: 310px; */
    }

    h2 {
      font-family: "NotoSansKR", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 60px;
      line-height: 120%;
      color: #28292b;
      span {
        font-family: "NotoSansKR", sans-serif;
        font-style: normal;
        font-weight: bold;
        display: block;
        font-size: 60px;
        line-height: 120%;
        color: #21d5cb;
        @media (max-width: 980px) {
          font-size: 33px;
        }
        @media (max-width: 760px) {
          margin: 0;
          margin-top: 100px;
        }
      }

      @media (max-width: 1120px) {
        font-size: 33px;
      }
      @media (max-width: 680px) {
        margin: 0;
      }
      @media (max-width: 453px) {
        margin: 0 auto;
        width: 310px;
        font-style: normal;
        font-weight: bold;
        font-size: 33px;
        line-height: 140%;
        text-align: center;
        font-family: "NotoSansKR", sans-serif;
      }
    }
    .app-container {
      margin-top: 26px;
    }
    .subtitile {
      padding-right: 101px;
      @media (max-width: 1120px) {
        padding-right: 0px;
      }
    }
    p {
      margin-top: 45px;
      width: 620px;
      font-family: "NotoSansKR Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 48px;
      color: #494b52;

      span {
        color: #21d5cb;
      }
      @media (max-width: 1120px) {
        font-size: 16px;
        line-height: 30px;
        width: auto;
        padding-right: 0px;
      }
      @media (max-width: 760px) {
        margin-top: 18px;
      }
    }
    .jp {
      font-size: 31px;
    }
    img:first-child {
      margin-right: 16px;
    }
    img:hover {
      opacity: 0.8;
    }
    img {
      width: 156px;
      @media (max-width: 832px) {
        width: 120px;
      }
      @media (max-width: 760px) {
        width: 104px;
      }
    }
  }
  .right-side {
    img {
      @media (max-width: 1110px) {
        width: 450px;
      }
      @media (max-width: 1030px) {
        width: 400px;
      }
      @media (max-width: 980px) {
        width: 320px;
      }
      @media (max-width: 880px) {
        width: 230px;
      }
      @media (max-width: 800px) {
        width: 200px;
      }
      @media (max-width: 760px) {
        padding-top: 22px;
        width: 310px;
        height: 473px;
      }
    }
  }
`;

const BlueBlockContainer = styled.div`
  background: #21d5cb;
  @media (max-width: 550px) {
    padding: 0 55px;
  }
`;
const BlueBlock = styled.section`
  padding: 110px 0;
  margin-top: -5px;
  font-family: "Noto Sans";
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;

  @media (max-width: 550px) {
    width: 100%;
    padding: 55px 0;
  }
  .content-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 290px);
    @media (max-width: 550px) {
      grid-template-columns: repeat(auto-fill, 310px);
      font-size: 16px;
    }
    justify-content: space-between;
    @media (max-width: 733px) {
      justify-content: center;
    }
    grid-gap: 60px;
    text-align: center;

    .blue-block-text {
      display: flex;
      flex-direction: column;
      @media (max-width: 550px) {
        margin-left: 20px;
        align-items: flex-start;
        text-align: left;
      }
    }

    .block-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 550px) {
        flex-direction: row;
        align-items: center;
      }
      img {
        width: 130px;
        @media (max-width: 550px) {
          width: 73px;
          align-self: center;
        }
      }
      h4 {
        margin-top: 36px;
        @media (max-width: 550px) {
          margin-top: 0px;
        }
        margin-bottom: 24px;
        font-family: "NotoSansKR", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 100%;
        text-align: center;
        color: #494b52;
        @media (max-width: 550px) {
          font-size: 17px;
          margin-bottom: 8px;
        }
      }
      p {
        font-family: "Noto Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        @media (max-width: 550px) {
          justify-content: flex-start;
          text-align: left;
          font-size: 16px;
          width: 218px;
          line-height: 24px;
        }
        color: #494b52;
      }
    }
  }
`;

const AppsIntroduction = styled.section`
  font-family: "Noto Sans";
  padding-top: 140px;
  padding-bottom: 130px;
  background-image: url(${appsIntroDesktop});
  background-size: cover;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 32px;
    line-height: 140%;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 32px;
    @media (max-width: 550px) {
      font-size: 27px;
    }
    @media (max-width: 550px) {
      font-style: normal;
      font-weight: bold;
      font-size: 27px;
      line-height: 140%;
      text-align: center;
      font-family: "NotoSansKR", sans-serif;
      width: 328px;
    }
  }
  img.mr {
    margin-right: 16px;
  }
  img {
    width: 156px;
    @media (max-width: 550px) {
      width: 104px;
    }
  }
  img:hover {
    opacity: 0.8;
  }
`;

const Features = styled.section`
  span {
    display: inline-block;
  }
  .reverse {
    flex-direction: row-reverse;
    max-width: 1200px;
    margin: 0 auto;
    img {
      margin-left: 75px;
      @media (max-width: 980px) {
        margin-left: 0;
      }
      @media (max-width: 1200px) {
        margin-left: 10px;
      }
    }
  }
  .feature-content-background {
    background-color: #f7f7f8;
  }
  .feature-content {
    display: flex;
    padding: 120px 0;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    img {
      height: 485px;
      width: 483px;
    }
    @media (max-width: 430px) {
      width: 100%;
      padding: 50px 35px;
    }
    @media (max-width: 980px) {
      flex-direction: column-reverse;
      align-items: center;
      img {
        width: 350px;
        height: 348px;
      }
    }
    @media (max-width: 430px) {
      img {
        margin-top: 30px;
        height: 348px;
        width: 350px;
      }
    }

    .text-box {
      margin-left: 75px;
      align-self: center;
      h5.EN {
        color: #494b52;
      }
      .kr {
        font-size: 32px;
        line-height: 160%;
        color: #494b52;
        span {
          display: block;
        }
        @media (max-width: 400px) {
          font-style: normal;
          font-weight: bold;
          font-family: "NotoSansKR", sans-serif;

          font-size: 28px;
          line-height: 40px;
          width: 284px;
        }
      }
      @media (max-width: 1200px) {
        margin-left: 10px;
        h5.EN {
          width: 80%;
        }
      }

      @media (max-width: 980px) {
        margin-left: 0;
        h5.EN {
          width: 100%;
          color: #494b52;
        }
      }

      @media (max-width: 500px) {
        h5.KR.first-one {
          width: 243px;
        }
        h5.KR.last-one {
          width: 260px;
        }
        h5.EN {
          color: #494b52;
        }
      }
      @media (max-width: 375px) {
        h5.KR {
          width: 65%;
        }
        h5.KR.first-one {
          width: 243px;
        }
      }

      h5 {
        font-family: "NotoSansKR", sans-serif;

        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 160%;
        color: #494b52;
        @media (max-width: 980px) {
          font-size: 28px;
        }
      }
    }
    p {
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 160%;
      color: #494b52;
      width: 540px;
      @media (max-width: 980px) {
        font-size: 17px;
        margin-top: 20px;
        margin-bottom: 30px;
      }
      @media (max-width: 500px) {
        width: 300px;
        margin: 0;
      }
    }
  }
`;

const Footer = styled.footer`
  padding: 29px 0px;
  background: #1b1c1d;
  text-align: center;
  p {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: rgba(255, 255, 255, 0.5);
  }
`;
const NavbarLeft = styled.div`
  position: relative;
  height: 60px;
  width: 60px;
`;

export const OxfordComponents = {
  Container,
  Navbar,
  Header,
  BlueBlock,
  AppsIntroduction,
  Features,
  Footer,
  CustomButton,
  NavbarLeft,
  BlueBlockContainer,
};
